<template>
    <eden-confirm-dialog
      title="Reset Cleaner Password"
      button-text="Reset"
      button-type="success"
      :show.sync="shouldShow"
      @confirm="reset"
    >
      <p>
        Are you sure you want to reset
        <strong class="text-grey-primary">{{ cleanerName }}'s </strong> password?
      </p>
    </eden-confirm-dialog>
  </template>
    
    <script>
  import cleaners from "@/requests/logistics/index.js";
  
  export default {
    name: "CleanerPasswordReset",
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      cleaner: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {};
    },
    computed: {
      shouldShow: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit("update:show", value);
        },
      },
      cleanerName() {
        return this.formatName(this.cleaner.firstname + " " + this.cleaner.lastname);
      },
    },
    methods: {
      closeEvent() {
        this.shouldShow = false;
      },
      reset() {
        const payload = {
          third_party_user_id: this.cleaner.id,
          user_type: "cleaner",
        };
        cleaners
          .reset(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.shouldShow = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      },
    },
  };
  </script>
    
    <style lang="scss"></style>
    